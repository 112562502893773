import React, { useEffect, useState, useRef } from "react";

import AppBar from "./AppBar";

const ParentComponent = (props) => {
  return (
    <div
      style={{
        height: window.innerHeight,
        width: "100%",
      }}
    >
      {props.showAppBar !== false && (
        <AppBar title={props.title} backUrl={props.backUrl} />
      )}
      {props.children}
    </div>
  );
};

export default ParentComponent;
