const styles = {
  main_container: {},
  content_container: {
    position: "absolute",
    top: "10%",

    height: "60%",
    width: "90%",
    // backgroundColor: "yellow",
    alignItems: "center",
    // justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  counter_text: {
    marginTop: 20,
    fontSize: "3em",
    fontWeight: "500",
  },
  counter_title_text: {},
  from_to_text: {
    fontSize: "1.3em",
    color: "grey",
    textAlign: "center",
    marginBottom: 5,
    width: "100%",
    // backgroundColor: "green",
  },
  slider: {
    marginTop: "20px",
    width: "90%",
  },
  from_to_digit_text: {
    textAlign: "center",
    border: "none",
    // borderColor: "none",
    fontSize: "1.3em",
    // fontColor: "black",
    //flex: 1,
    display: "flex",
    width: "50%",
    alignSelf: "center",
    margin: 0,
    // margin: "auto",

    // width: "100%",
    //backgroundColor: "transparent",
    // backgroundColor: "red",
  },
  button: {
    backgroundColor: "#F2F2F2",
    color: "black",
    textAlign: "center",
    fontWeight: "bold",
    padding: 10,
    borderRadius: 10,
    width: "100%",
  },
  speed_text: { color: "black", fontWeight: "bold", marginBottom: 5 },
  speed_info_text: { fontSize: "1.1em" },
  from_to_container: {
    marginTop: 20,
    // backgroundColor: "green",
    display: "flex",
    flexDirection: "row",
    width: "80%",
  },
  from_to_content: {
    //backgroundColor: "red",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
};

module.exports = {
  styles,
};
