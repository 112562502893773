import React, { useEffect, useState, useRef } from "react";
import { styles } from "./styles";
import MySlider from "./components/slider";
import InputDisplayBasic from "./components/inputsdisplaybasic";
import Button from "../../reusables/Button";
import { SliderPicker } from "react-color";
import { HexColorPicker } from "react-colorful";
import ColorSelection from "./components/colorselection";
import BottomControls from "./components/bottomcontrols";
import Logo from "../../reusables/logo";
import ParentComponent from "../../reusables/ParentComponent";
import AppBar from "../../reusables/AppBar";
import { useAddToHomescreenPrompt } from "../../reusables/AddToHomeScreen";
const Counter = (props) => {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();

  const [counter, setCounter] = useState(0);
  const [speed, setSpeed] = useState(2);
  const [counting, setCounting] = useState(false);
  const [step, setStep] = useState(1);
  const [direction, setDirection] = useState(1);
  const [textColor, setTextColor] = useState("#000");
  const [greenScreen, setGreenScreen] = useState(false);
  const [greenScreenBox, setGreenScreenBox] = useState(false);

  useEffect(() => {}, []);
  // document.body.style.overflow = "hidden";

  return (
    <ParentComponent
      title="Green Screen Numbers"
      backUrl="/"
      showAppBar={greenScreen ? false : true}
    >
      <button onClick={promptToInstall}>Add to Home Screen</button>

      <div
        style={{
          height: window.innerHeight,
          marginTop: greenScreen ? 0 : "2%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          // backgroundColor: "green",
        }}
      >
        <div
          style={{
            // marginTop: greenScreen ? 0 : "5%",

            height: "100%",
            width: greenScreen ? "100%" : "90%",
            backgroundColor: greenScreen ? "#01F731" : "white",
            alignItems: "center",
            alignSelf: "center",
            // justifyContent: "center",
            display: "flex",
            gap: "10px",
            justifySelf: "center",
            flexDirection: "column",
          }}
        >
          <InputDisplayBasic
            counter={counter}
            speed={speed}
            step={step}
            counting={counting}
            setCounter={setCounter}
            direction={direction}
            textColor={textColor}
            greenScreen={greenScreen}
            greenScreenBox={greenScreenBox}
            style={{}}
          />
          {!greenScreen && <MySlider setSpeed={setSpeed} style={{}} />}

          {!greenScreen && (
            <ColorSelection
              setGreenScreenBox={setGreenScreenBox}
              greenScreenBox={greenScreenBox}
              setTextColor={setTextColor}
              textColor={textColor}
            />
          )}

          {!greenScreen && (
            <BottomControls
              style={{ flex: 0.5, display: "flex" }}
              counter={counter}
              setCounter={setCounter}
              counting={counting}
              setCounting={setCounting}
              direction={direction}
              setDirection={setDirection}
            />
          )}

          {!greenScreen && (
            <div
              style={{
                display: "flex",
                flex: 3,
                // backgroundColor: "red",
                width: "100%",
              }}
            ></div>
          )}
        </div>
        <div
          style={{
            padding: 10,
            backgroundColor: "#F2F2F2",
            borderRadius: 10,
            position: "absolute",
            bottom: 10,
            right: 10,
          }}
          onClick={() => {
            setGreenScreen(!greenScreen);
          }}
        >
          {greenScreen ? "Disable" : "Enable"} Full Green Screen
        </div>
      </div>
    </ParentComponent>
  );
};

export default Counter;
