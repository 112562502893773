import React, { useEffect, useState, useRef } from "react";
import { styles } from "../styles";
import Button from "../../../reusables/Button";

const BottomControls = (props) => {
  useEffect(() => {}, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        gap: "3%",
        // marginTop: 20,
        ...props.style,
      }}
    >
      <Button
        text={props.counting ? "Stop" : "Start"}
        style={{ flex: 1, fontWeight: "bold" }}
        onClick={() => {
          if (isNaN(props.counter)) props.setCounter(0);

          props.setCounting(!props.counting);
        }}
      />
      <div
        style={{
          flex: 3,
          // backgroundColor: "green",
          width: "100%",
          display: "flex",
          gap: "1%",
          flexDirection: "row",
        }}
      >
        <Button
          text="Count Up"
          selected={props.direction > 0 ? true : false}
          style={{ flex: 1 }}
          onClick={() => {
            props.setDirection(1);
          }}
        />
        <Button
          text="Count Down"
          selected={props.direction > 0 ? false : true}
          style={{ flex: 1 }}
          onClick={() => {
            props.setDirection(-1);
          }}
        />
      </div>
    </div>
  );
};

export default BottomControls;
