import React, { useEffect, useState, useRef } from "react";
import { Divider, Image, Row, Col } from "antd";
import HomeItem from "../../reusables/HomeItem";
import Grid from "../../reusables/Grid";
import data from "./data.json";

const Home = (props) => {
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    setRowData(data);
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "5%",
      }}
    >
      <span
        style={{
          fontSize: "1.5em",
          fontWeight: "bold",
          alignSelf: "center",
        }}
      >
        SOSU
      </span>

      <Grid arrOfItems={rowData} />

      <div></div>
    </div>
  );
};

export default Home;
