import React, { useEffect, useState, useRef } from "react";
const ExpandInput = (props) => {
  const [width, setWidth] = useState(1);
  const [scrollWidth, setScrollWidth] = useState(1);
  const [fontSize, setFontSize] = useState(props?.style?.fontSize || 1);
  const [sizeMap, setSizeMap] = useState({});
  function changeHandler(event) {
    setWidth(event.target.clientWidth);
    setScrollWidth(event.target.scrollWidth);
    if (sizeMap[event.target.value.length]) {
      setFontSize(sizeMap[event.target.value.length]);
    } else if (scrollWidth > width) {
      setFontSize(fontSize * 0.9);
    }
    setSizeMap({ ...sizeMap, [event.target.value.length]: fontSize });
    // console.log(width, scrollWidth, event);
    // console.log(sizeMap);
    props.onChange(event);
    // props.setCounter(event.target.value);
  }
  return (
    <>
      <input
        onChange={changeHandler}
        value={props.value}
        style={{
          ...props.style,
          fontSize: fontSize + "em",
        }}
      />
    </>
  );
};

export default ExpandInput;
