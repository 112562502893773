import React, { useEffect, useState, useRef } from "react";
// import Carousel from "react-material-ui-carousel";
import { DatePicker, Spin } from "antd";
import ImageGallery from "react-image-gallery";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-image-gallery/styles/css/image-gallery.css";

import dayjs from "dayjs";
import {
  getCuriousityRoverManifest,
  getCuriousityRoverPhotos,
} from "./apimanager";
import "./index.css";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import AppBar from "../../reusables/AppBar";
const EasySpace = (props) => {
  const slider = useRef(null);
  const [loading, setLoading] = useState(false);

  const [date, setDate] = useState(dayjs());
  const [photos, setPhotos] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);

  function isDayDisabled(day) {
    // return false;
    var formattedDay = day.format("YYYY-MM-DD");
    // console.log(`Checking ${formattedDay}`);
    // console.log(availableDates.includes(formattedDay));
    if (availableDates.includes(formattedDay)) return false;
    return true;
  }
  async function runUseEffect() {
    setLoading(true);
    await parseManifest();
    //console.log(date.format("YYYY-MM-DD"));
    var data = await getCuriousityRoverPhotos(date.format(`YYYY-MM-DD`));
    // console.log(data);
    setLoading(false);
    var arrPhotos = [];
    if (data.photos.length === 0) return;
    // for (var i = 0; i < data.photos; i++) {
    for (var photo of data.photos) {
      // var photo = data.photos[i];
      // console.log(photo.id);
      // arrPhotos.push(
      //   <img
      //     key={photo.id}
      //     src={photo.img_src}
      //     style={{
      //       objectFit: "fill",
      //       width: "100%",
      //       // height: "500px",
      //       height: "100%",
      //     }}
      //   ></img>
      // );
      arrPhotos.push({
        original: photo.img_src,
        thumbnail: photo.img_src,
      });
    }

    setPhotos(arrPhotos);
  }
  useEffect(() => {
    runUseEffect();
  }, [date]);

  useEffect(() => {
    console.log(photos);
  }, [photos]);

  function findCommonElements3(arr1, arr2) {
    return arr1.some((item) => arr2.includes(item));
  }
  async function parseManifest(manifest) {
    var manifest = await getCuriousityRoverManifest();
    var cameras = ["NAVCAM"];
    var dates = [];
    for (var photos of manifest.photo_manifest.photos) {
      if (findCommonElements3(cameras, photos.cameras))
        dates.push(photos.earth_date);
    }
    dates.reverse();
    // console.log(dates);
    setAvailableDates(dates);
  }

  return (
    <div
      style={{
        width: "100%",
        height: window.innerHeight,
        // backgroundColor: "lightgreen",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppBar title="Mars Curiosity Viewer" backUrl="/" />
      <div
        style={{
          marginTop: "5%",
          width: "90%",
          alignSelf: "center",
          height: "60%",
          // backgroundColor: "yellow",
          display: "flex",
          flexDirection: "column",
          gap: "2%",
        }}
      >
        <div
          style={{
            // flex: 1,
            alignSelf: "center",
            display: "flex",
            width: "100%",
            backgroundColor: "#F2F2F2",
            borderRadius: 10,
            border: "none",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              fontWeight: "500",
              fontSize: "1em",
              marginLeft: "2%",
              marginRight: "2%",
              marginTop: "2%",
            }}
          >
            Choose a date
          </span>
          <DatePicker
            defaultValue={date}
            onChange={(day) => {
              setDate(day);
            }}
            size={"large"}
            style={{ margin: "2%" }}
            disabledDate={isDayDisabled}
          />
        </div>
        <div
          style={{
            alignSelf: "center",
            display: "flex",
            width: "100%",
            flex: 1,
            backgroundColor: "#F2F2F2",
            borderRadius: 10,
            border: "none",
            // height: "100%",
            flexDirection: "column",
            justifyContent: "center",
            // alignItems: "center",
          }}
        >
          {!loading && photos.length === 0 && (
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/0/02/OSIRIS_Mars_true_color.jpg"
              style={{
                // backgroundColor: "yellow",
                objectFit: "fill",
                // width: "100%",
                // height: "500px",
                // height: "100%",
                // alignSelf: "center",
                margin: "2%",
              }}
            />
          )}
          {!loading && photos.length > 0 && (
            <div
              style={{
                margin: "2%",
                // alignSelf: "center",
              }}
            >
              <ImageGallery showIndex={true} items={photos} />
            </div>
          )}
          {loading && (
            <Spin
              size="large"
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EasySpace;
