import React, { useEffect, useState, useRef } from "react";
import { HexColorPicker } from "react-colorful";
import Button from "../../../reusables/Button";
import { Checkbox } from "@mui/material";

const ColorSelection = (props) => {
  return (
    <div
      style={{
        width: "100%",
        // marginTop: 20,
        flexDirection: "column",
        backgroundColor: "#F2F2F2",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        borderRadius: 10,
        paddingTop: "2%",
        paddingBottom: "2%",
        flex: 3,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // backgroundColor: "green",
          alignItems: "center",
          width: "90%",
          marginBottom: "1%",
        }}
      >
        <span
          style={{
            fontSize: "1em",
            fontWeight: "bold",
            // marginBottom: 10,
            flex: 1,
            height: "100%",
            // backgroundColor: "yellow",
            // alignSelf: "flex-start",
          }}
        >
          Text Color
        </span>

        <span
          style={{
            flex: 1,
            fontSize: "0.8em",
            textAlign: "right",
          }}
        >
          Green Screen?
        </span>
        <Checkbox
          size="10px"
          sx={{
            p: "0",
          }}
          checked={props.greenScreenBox}
          onChange={() => {
            props.setGreenScreenBox(!props.greenScreenBox);
          }}
        />
      </div>

      <HexColorPicker
        color={props.textColor}
        onChange={(event) => {
          props.setTextColor(event);
        }}
        style={{
          width: "90%",
          flex: 10,
        }}
      />
    </div>
  );
};

export default ColorSelection;
