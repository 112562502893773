import React, { useEffect, useState, useRef } from "react";
import { styles } from "../styles";
import { AutoTextSize } from "auto-text-size";
import ExpandInput from "../../../reusables/expandinput";
const InputDisplayBasic = (props) => {
  function changeHandler(event) {
    props.setCounter(event.target.value);
  }
  useEffect(() => {
    if (props.counting === true) {
      // console.log(`Counter:`, props.counter);
      var myIntervalId = setInterval(() => {
        // console.log(`Interval Call`);
        var append = parseFloat(props.counter) + 1 * props.direction;
        if (props.speed < 0.001) {
          var diff = parseInt(0.001 / props.speed);
          var added = "";
          for (var i = 0; i < diff.toString().length; i++) {
            added += "1";
          }
          added = parseInt(added);
          added *= props.direction;
          console.log(added);
          console.log(props.speed, diff);
          append = parseFloat(props.counter) + added + diff * props.direction;
        }
        props.setCounter(append);
      }, props.speed * 1000);
    }
    return () => clearInterval(myIntervalId);
    // return () => (parseFloat(counter) !== parseFloat(to) ? clearInterval(myIntervalId) : null);
  }, [
    props.counter,
    props.counting,
    props.direction,
    props.greenScreen,
    props.textColor,
    props.greenScreenBox,
  ]);

  return props.greenScreen ? (
    <ExpandInput
      onChange={changeHandler}
      value={props.counter}
      style={{
        width: "90%",
        height: "50%",
        overflow: "auto",
        margin: "auto",
        position: "absolute",
        textAlign: "center",
        backgroundColor: "transparent",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        fontSize: 3,
        fontWeight: "500",
        border: "none",
        color: props.textColor ? props.textColor : "#000",
      }}
    />
  ) : (
    <div
      style={{
        // flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // backgroundColor: "red",
        width: "100%",
      }}
    >
      {!props.greenScreen && (
        <span style={{ fontSize: "1em", color: "#000" }}>Current Counter</span>
      )}
      {!props.greenScreen && (
        <span style={{ fontSize: "0.8em", color: "#C4C4C4" }}>
          Click to set a custom starting point
        </span>
      )}
      <ExpandInput
        onChange={changeHandler}
        value={props.counter}
        style={{
          fontSize: 3,
          fontWeight: "500",
          textAlign: "center",
          border: "none",
          display: "flex",
          width: "100%",
          height: 50,
          color: props.textColor ? props.textColor : "#000",
          paddingTop: props.greenScreenBox ? 20 : 0,
          paddingBottom: props.greenScreenBox ? 20 : 0,
          // minWidth: "50%",
          // width: width + "ch",
          alignSelf: "center",
          // margin: 0,
          backgroundColor: props.greenScreenBox ? "#01F731" : "transparent",
        }}
      />
    </div>
  );
};

export default InputDisplayBasic;
