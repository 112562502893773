import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter,
  Switch,
  Redirect,
  Route,
  Routes,
} from "react-router-dom";
import Counter from "./micro-frontends/counter/app";
import Logo from "./reusables/logo";
import { useDetectAdBlock } from "adblock-detect-react";
import { useEffect } from "react";
import EasySpace from "./micro-frontends/easyspace/app";
import Home from "./micro-frontends/home/app";
function App() {
  document.body.style.overflow = "hidden";
  // const adBlockDetected = useDetectAdBlock();
  // useEffect(() => {
  //   if (adBlockDetected) {
  //     console.log(`Ad Block Detected`);
  //   }
  // });

  return (
    <BrowserRouter>
      {/* <Logo
        style={{
          position: "absolute",
          top: 5,
          right: 5,
        }}
      /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/counter" element={<Counter />} />
        <Route path="/mars/curiosity" element={<EasySpace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
