import React, { useEffect, useState, useRef } from "react";
import { Divider, Image, Row, Col } from "antd";
import HomeItem from "./HomeItem";

const Grid = (props) => {
  const [rows, setRows] = useState([]);

  function extractRows() {
    var elements = props.arrOfItems;
    // console.log(elements);

    var arrOfRows = [];
    for (var i = 0; i < elements.length; i += 2) {
      var currentRow = elements.slice(i, i + 2);
      console.log(currentRow, elements);
      if (currentRow.length === 1) {
        arrOfRows.push(
          <Row
            key={i}
            style={{ margin: "2%", marginRight: "2%", marginTop: "2%" }}
            gutter={[24, 24]}
          >
            <Col key={currentRow[0].url} span={12}>
              <HomeItem
                url={currentRow[0].url}
                style={{
                  width: "90%",
                  height: window.innerWidth * 0.4,
                }}
                image={currentRow[0].image}
                title={currentRow[0].title}
                description={currentRow[0].description}
              />
            </Col>
          </Row>
        );
      } else if (currentRow.length === 2) {
        arrOfRows.push(
          <Row
            key={i}
            style={{
              //   backgroundColor: "yellow",
              marginLeft: "2%",
              marginRight: "2%",
              marginTop: "2%",
            }}
            gutter={[24, 24]}
          >
            <Col key={currentRow[0].url} span={12}>
              <HomeItem
                url={currentRow[0].url}
                style={{
                  width: "100%",
                  height: window.innerWidth * 0.4,
                }}
                image={currentRow[0].image}
                title={currentRow[0].title}
                description={currentRow[0].description}
              />
            </Col>
            <Col key={currentRow[1].url} span={12}>
              <HomeItem
                url={currentRow[1].url}
                style={{
                  width: "100%",
                  height: window.innerWidth * 0.4,
                }}
                image={currentRow[1].image}
                title={currentRow[1].title}
                description={currentRow[1].description}
              />
            </Col>
          </Row>
        );
      }

      console.log(arrOfRows);
    }
    setRows(arrOfRows);
  }

  useEffect(() => {
    extractRows();
  }, [props.arrOfItems]);

  return (
    <div
      style={{
        marginTop: "2%",
        height: window.innerHeight,
        width: "100%",
        // backgroundColor: "red",
        overflow: "auto",
      }}
    >
      {rows}
    </div>
  );
};

export default Grid;
