import React, { useEffect, useState, useRef } from "react";
import { Slider } from "@mui/material";
import { styles } from "../styles";
const MySlider = (props) => {
  const [mark, setMark] = useState(0);
  const marks = [
    {
      value: 0,
      printable: "Normal",
      speed: 1,
    },
    {
      value: 1,
      printable: "Fast",
      speed: 0.1,
    },
    {
      value: 2,
      printable: "Super Fast",
      speed: 0.01,
    },
    {
      value: 3,
      printable: "Ultra Fast",
      speed: 0.001,
    },
    {
      value: 4,
      printable: "Flash",
      speed: 0.0001,
    },
    {
      value: 5,
      printable: "Super Flash",
      speed: 0.00001,
    },
    {
      value: 6,
      printable: "Ultra Flash",
      speed: 0.000001,
    },
  ];

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        //   alignItems: "center",
        backgroundColor: "#F2F2F2",
        paddingTop: "2%",
        // paddingBottom: 10,
        // padding: 10,
        borderRadius: 10,
        flexDirection: "column",
        flex: 1,
        //   backgroundColor: "green",
      }}
    >
      <div
        style={{
          width: "95%",
          alignSelf: "center",
          // marginBottom: 10,
          flex: 1,
          display: "flex",
          justifyContent: "space-between",
          // backgroundColor: "green",
        }}
      >
        <span
          style={{
            fontSize: "1em",
            fontWeight: "bold",
            height: "100%",
          }}
        >
          Speed Controls
        </span>
        <span
          style={{
            fontSize: "1em",
            fontWeight: "bold",
            height: "100%",
          }}
        >
          {marks[mark].printable}
        </span>
      </div>
      <div
        style={{
          width: "90%",
          alignSelf: "center",
          justifyContent: "center",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          flex: 3,
          // backgroundColor: "yellow",
        }}
      >
        <Slider
          aria-label="Custom marks"
          defaultValue={mark}
          // getAriaValueText={valuetext}
          // step={"min"}
          onChange={(event) => {
            var selection = event.target.value;
            setMark(selection);
            props.setSpeed(marks[selection].speed);
          }}
          max={marks.length - 1}
          // track={"inverted"}
          valueLabelDisplay="off"
          marks={marks}
        />
      </div>
    </div>
  );
};

export default MySlider;
