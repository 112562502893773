import React, { useEffect, useState, useRef } from "react";
import { Divider, Image, Spin } from "antd";
import { useNavigate } from "react-router-dom";

const HomeItem = (props) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        backgroundColor: "#f2f2f2",
        borderRadius: 10,
        display: "flex",
        height: "100%",
        width: "100%",
        // gap: "5%",
        flexDirection: "column",
        // alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "0 3px 3px rgb(0 0 0 / 0.2)",
        ...props.style,
      }}
      onClick={() => {
        navigate(props.url);
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          // backgroundColor: "yellow",
          justifyContent: "center",
          alignItems: "center",
          // alignSelf: "center",
        }}
      >
        <span
          style={{
            fontSize: "0.8em",
            fontWeight: "bolder",
            // backgroundColor: "green",
          }}
        >
          {props.title}
        </span>
      </div>

      {/* <Divider style={{ marginBottom: "2%", marginTop: 0 }} /> */}
      <div
        style={{
          flex: 4,
          display: "flex",
          // backgroundColor: "red",
          // maxHeight: "100%",
          backgroundSize: "contain",
          // backgroundRepeat: "no-repeat",
          backgroundImage: `url(${props.image})`,
        }}
      ></div>
      <div
        style={{
          flex: 1,
          display: "flex",
          // backgroundColor: "yellow",
          justifyContent: "center",
          alignItems: "center",
          // alignSelf: "center",
        }}
      >
        <span
          style={{
            fontSize: "0.6em",
            fontWeight: "normal",
            textAlign: "center",
            // backgroundColor: "green",
          }}
        >
          {props.description}
        </span>
      </div>
    </div>
  );
};

export default HomeItem;
