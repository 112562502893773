import React, { useEffect, useState, useRef } from "react";

const Button = (props) => {
  return (
    <div
      style={{
        backgroundColor: props.selected ? "#D3D3D3" : "#F2F2F2",
        color: "black",
        textAlign: "center",
        fontWeight: props.bold ? "bold" : "normal",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        padding: "1%",
        height: "100%",
        borderRadius: 10,
        width: "100%",
        ...props.style,
      }}
      onClick={props.onClick}
    >
      {props.text}
    </div>
  );
};

export default Button;
