import React, { useEffect, useState, useRef } from "react";
import { LeftCircleOutlined, LeftCircleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const AppBar = (props) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        backgroundColor: "#F2F2F2",
        height: "5%",
        marginLeft: "2%",
        marginRight: "2%",
        marginTop: "2%",
        borderRadius: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        ...props.style,
      }}
    >
      <span
        style={{
          fontSize: "1em",
          fontWeight: "bold",
        }}
      >
        {props.title}
      </span>

      <span
        style={{
          fontSize: "0.8em",
          fontWeight: "bold",
          position: "absolute",
          top: "2%",
          right: "2%",
        }}
      >
        sosu.app
      </span>
      {props.backUrl && (
        <LeftCircleFilled
          style={{
            fontSize: "1.2em",
            fontWeight: "bold",
            position: "absolute",
            //top: "2%",
            left: "2%",
          }}
          onClick={() => {
            navigate(props.backUrl);
          }}
        />
      )}
    </div>
  );
};

export default AppBar;
